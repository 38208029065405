// Required JS from local files
import { dfmAlert } from './dfm5';

function contactUs() {
    dfmAlert(`
        <h3>Need to get in touch?</h3>
        <p>
            For general enquiries, product help and feedback, or to report an issue please email our support team at 
            <a href='mailto:support@drfrost.org'>support@drfrost.org</a>.
        </p>
    `);
 }

const contactUsLink = document.getElementById("footer-contact-link");

contactUsLink.addEventListener("click", () => contactUs());